/* App.css */
body {
  font-family: 'Poppins', sans-serif;
  background-color: #f8f9fa; /* Off-white background */
  color: #343a40; /* Soft black text color */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Raleway', sans-serif;
  color: #343a40; /* Soft black text color */
}

/* Button Styling */
.btn-primary {
  background-color: #1c2e11 !important; /* Army green */
  color: #fff !important; /* White text */
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 5px !important;
  font-weight: bold !important;
  transition: background-color 0.3s ease !important;
  margin-left: 10px; /* Add margin to separate buttons */
}

.btn-primary:hover {
  background-color: #ac8320 !important; /* Golden yellow */
}

.btn-primary:focus {
  outline: none !important;
  box-shadow: 0 0 0 3px rgba(172, 131, 32, 0.5) !important; /* Accessible focus style */
}

.btn-secondary {
  background-color: #343a40; /* Soft black */
  color: #fff; /* White text */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-left: 10px; /* Add margin to separate buttons */
}

.btn-secondary:hover {
  background-color: #ac8320; /* Golden yellow */
}

.btn-secondary:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(172, 131, 32, 0.5); /* Accessible focus style */
}

.pale-yellow-background {
  background-color: #fff8dc; /* Pale yellow background */
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
}

/* Navbar Styling */
.navbar-light .navbar-nav .nav-link {
  color: #343a40; /* Soft black text color */
  transition: color 0.3s ease;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #ac8320; /* Golden yellow */
}

.navbar-brand {
  font-weight: bold;
  color: #1c2e11; /* Army green */
}

.navbar-brand:hover {
  color: #ac8320; /* Golden yellow */
}

.dropdown-menu {
  background-color: #f8f9fa; /* Off-white background */
}

.dropdown-item {
  color: #343a40; /* Soft black text color */
}

.dropdown-item:hover {
  color: #fff;
  background-color: #1c2e11; /* Army green */
}

.dropdown-item:focus,
.dropdown-item:active {
  outline: none !important;
  background-color: #ac8320 !important; /* Golden yellow */
  color: #fff !important;
}

/* Flexbox to ensure footer is at the bottom */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}

/* Footer styles */
.footer {
  width: 100%;
  padding: 10px 15px;
  background-color: #f8f9fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allow items to wrap */
  box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
}

.footer .text-muted {
  flex: 1 1 auto; /* Allow the text to flex and take up available space */
  margin-bottom: 0;
}

.footer .footer-links {
  display: flex;
  gap: 15px;
  flex: 1 1 auto; /* Allow the links to flex and take up available space */
  justify-content: flex-end; /* Align links to the right */
}

.footer .footer-links a {
  color: #1c2e11; /* Army green */
  text-decoration: none;
  padding: 0 5px;
}

.footer .footer-links a:hover {
  text-decoration: underline;
  color: #ac8320; /* Golden yellow */
}

/* Media Queries for responsiveness */
@media (max-width: 576px) {
  .footer {
    display: grid; /* Use grid layout */
    grid-template-columns: 1fr 1fr; /* Two columns */
    grid-gap: 10px; /* Gap between grid items */
    align-items: center; /* Center align items */
    text-align: center; /* Center align text */
  }

  .footer .text-muted {
    grid-column: span 2; /* Span both columns */
    margin-bottom: 10px;
  }

  .footer .footer-links {
    display: contents; /* Allow grid to manage layout */
  }

  .footer .footer-links a {
    margin: 0 auto; /* Center align links */
  }
}

/* FAQ Section Styling */
.faq-section {
  margin-bottom: 30px;
}

.faq-section h2 {
  font-size: 1.6rem;
  margin-bottom: 10px;
}

.faq-section p,
.faq-section ul,
.faq-section ol {
  margin-bottom: 15px;
  line-height: 1.6;
}

.faq-item {
  border-bottom: 1px solid #dee2e6; /* Add a subtle separator */
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.faq-item h4 {
  font-size: 1.2rem; /* Slightly larger font size for questions */
  margin-bottom: 10px;
}

/* Contact Section Styling */
.contact-section {
  text-align: left;
}

/* Link Styling */
.faq-section a, .contact-section a, .register-business-section a, .login a {
  color: #1c2e11; /* Army green */
  text-decoration: underline;
}

.faq-section a:hover, .contact-section a:hover, .register-business-section a:hover, .login a:hover {
  color: #ac8320; /* Golden yellow */
}

/* Get Started Section Styling */
.get-started-section {
  background-color: #fcf8e3; /* Pale yellow background */
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 30px; /* Add some space below the section */
}

.get-started-section h2 {
  color: #343a40; /* Dark gray or your secondary brand color */
  font-size: 1.6rem;
  margin-bottom: 10px;
}

.get-started-section p {
  line-height: 1.6;
  margin-bottom: 15px;
}

/* Register Business Section Styling */
.register-business-section {
  background-color: #fcf8e3; /* Pale yellow background */
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 30px; /* Add some space below the section */
}

.register-business-section h2 {
  color: #343a40; /* Dark gray or your secondary brand color */
  font-size: 1.6rem;
  margin-bottom: 10px;
}

.register-business-section p {
  line-height: 1.6;
  margin-bottom: 15px;
}

/* Listing Tips Section Styling */
.listing-tips {
  margin-bottom: 30px;
}

/* Section Divider */
.section-divider {
  border: 1px solid #ddd;
  margin: 30px 0;
}

.mt-4 {
  margin-top: 20px;
}
